<!-- 查看产品 -->
<template>
    <div v-loading="loading" class="asnBillDetail">
        <div class="headerBtns">
            <el-button size="small" type="primary" round icon="iconfont icon-zuo" @click="$router.go(-1)">
                {{ $t(`m.common.back`) }}
            </el-button>
        </div>
        <el-row>
            <el-col :span="24">
                <el-card>
                    <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="查看产品" name="first">
                            <div class="tableBox">
                                <el-table ref="detailTable" height="400" stripe highlight-current-row
                                    @selection-change="handleSelectionChange" :data="asnDetails" style="width: 100%"
                                    :header-cell-style="$parent.tableHeaderColor" border>
                                    <el-table-column type="index" label="序号" width="50">
                                    </el-table-column>
                                    <el-table-column prop="imageUrl" :label="$t(`m.common.picture`)">
                                        <template slot-scope="scope">
                                            <el-image style="width: 80px; height: 80px" :src="scope.row.imageUrl"
                                                :preview-src-list="[scope.row.imageUrl]">
                                            </el-image>
                                        </template>
                                    </el-table-column>
                                    <el-table-column show-overflow-tooltip prop="itemCode" label="SKU"> </el-table-column>
                                    <el-table-column show-overflow-tooltip prop="itemName" :label="$t(`m.common.product_name`)"> </el-table-column>
                                    <el-table-column prop="claimQuantity" label="数量"> </el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { formatListDataByKey } from "@/utils/DateUtils.js";
import _ from "lodash";

export default {
    name: "adoptClaimDetail",
    data() {
        return {
            formData: {},
            loading: false,
            isMoreSearch: false,
            isMoreSearchMaster: true,
            isMoreSearch2: false,
            isMoreSearch3: false,
            activeName: "first",
            searchData: {
                id: this.$route.params.claimMasterId,
            },
            searchDataItem: {
                pageNum: 1,
                pageSize: 5,
                total: null,
                keyWords: "",
                wareId: null,
                organizationId: null,
            },
            searchSelectCell: {
                pageNum: 1,
                pageSize: 5,
                total: null,
                keyWords: "",
                wareId: "",
                areaId: "",
                cellUseType: 0,
            },
            asnMaster: {},
            asnShipDetails: [],
            acceptRecords: [],
            exDetails: [],
            form: {
                asnMaster: {},
                asnDetails: [],
            },
            asnUdfHs: [],
            asnUdfDs: [],
            asnTypes: [],
            ids: [],
            items: [],
            suppliers: [],
            organizations: [],
            platforms: [],
            carriers: [],
            multipleSelection: [],
            multipleSelection2: [],
            qcUdfHs: [],
            qcUdfDs: [],
            qcTypes: [],
            batchTactics: [],
            cells: [],
            areas: [],
            packDetails: [],
            acceptExcess: null,
            innerVisibleItem: false,
            dialogNewVisible: false,
            dialogQcVisible: false,
            dialogReviewVisible: false,
            dialogAcceptVisible: false,
            innerVisibleCell: false,
            rules: {
                supplierId: [
                    {
                        required: true,
                        message: "请选择货主",
                        trigger: "blur",
                    },
                ],
                buyType: [
                    {
                        required: true,
                        message: "请选择供应商",
                        trigger: "blur",
                    },
                ],
            },

            formLabelWidth: "120px",

            formQc: {
                billSource: 0,
                insertType: -2,
                qcMaster: {},
                qcDetails: [],
            },
            formReview: {
                billNo: this.$route.params.billNo,
                reviewResult: null,
                caseDetailId: null,
                memo: "",
            },
            rulesReview: {
                memo: [
                    {
                        required: true,
                        message: "请填写审核原因",
                        trigger: "blur",
                    },
                ],
            },
            rulesAccept: {},
            asnDetails: []
        };
    },
    computed: {
        ...mapState([
            "abcLevels",
            "qcStates",
            "cellUseTypes",
            "cellAbcLevels",
            "cellPickTypes",
            "asnStates",
            "asnStatusTypes",
        ]),
        supplierId() {
            return this.form.asnMaster.supplierId;
        },
        isFile() {
            if (this.$route.params.fileUserId) {
                return true;
            } else {
                return false;
            }
        },
    },
    watch: {
        supplierId(val) {
            for (let i = 0; i < this.suppliers.length; i++) {
                if (this.suppliers[i].supplierId === val) {
                    this.form.asnMaster.supplierName = this.suppliers[i].supplierName;
                }
            }
        },
    },
    methods: {
        formatListDataByKey,
        // 获取产品信息列表
        getList() {
            this.loading = true;
            let url = "/claim/master/detail";
            IOT.getServerData(url, "get", this.searchData, (ret) => {
                this.loading = false;
                console.log(ret, 'ret');
                if (ret.code === 200) {
                    this.asnDetails = ret.data.detailList;
                    this.searchData.total = ret.total;
                    this.searchData.pageNum = ret.pageNumber;
                } else {
                    IOT.tips(ret.message || "服务器请求失败，稍后再试！", "error");
                }
            }, true);
        },
        // 合计产品信息
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "总计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                } else {
                    sums[index] = "";
                }
            });
            return sums;
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        handleCurrentChange() { },
        handleCurrentChange2(val) {
            this.pageNum = val;
            this.getItemData();
        },
        handleCurrentChange3(val) {
            this.searchSelectCell.pageNum = val;
            this.getCellData();
        },
        clearSearchSelectCell() {
            this.searchSelectCell.keyWords = "";
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        printList() {
            this.$router.push({
                name: "printView",
                params: {
                    printList: this.multipleSelection,
                },
            });
        },
        handleClose(done) {
            this.dialogNewVisible = false;
        },
    },
    created() {
        this.getList(); //产品信息
    },
    mounted() { },
};
</script>

<style>
.custom-tooltip {
    max-width: 400px !important;
    /* 设置 Tooltip 的最大宽度 */
}
</style>

<style scoped lang="less">
.asnBillDetail {}

:deep(.el-form-item) {
    margin-bottom: 0;
}
</style>
